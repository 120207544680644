.signCenterWrapper .signInfoItem {
  min-height: 1.546667rem;
  height: auto;
  border-radius: 0.32rem;
  color: #989BA5;
  background-color: #f5f6f9;
}

.signCenterWrapper .signInfoItem .signInfoLabel {
  display: inline-block;
  min-width: 2.533333rem;
  color: #cccccc;
}

.signCenterWrapper .signInfoItem .selectWrap {
  display: flex;
  width: 0.533333rem;
  height: auto;
  position: relative;
  margin-right: 0.133333rem;
}

.signCenterWrapper .signInfoItem .selectWrap .select {
  position: absolute;
  left: 0;
  top: 50%;
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 0.106667rem;
  transform: translate(0, -70%);
}

.signCenterWrapper .signInfoItem .selectWrap .selectTrue {
  background-color: #29C1C2;
}

.signCenterWrapper .signInfoItem .selectWrap .selectTrueDis {
  background-color: #BBBBBB;
}

.signCenterWrapper .signInfoItem .selectWrap .selectNo {
  border: 0.04rem solid #DDDDDD;
}

.signCenterWrapper .signInfoItem .selectWrap .selectDis {
  background-color: #BBBBBB;
}

.signCenterWrapper .signInfoItem .selectWrap .check {
  border: 0.04rem solid #FFFFFF;
  border-left: 0;
  border-bottom: 0;
  height: 0.106667rem;
  position: absolute;
  left: 0.066667rem;
  bottom: 0.2rem;
  width: 0.266667rem;
  transform: rotate(135deg) scaleY(1);
}

.signCenterWrapper .signInfoItem .noSelectWrap {
  display: flex;
  width: 0.266667rem;
  height: 1.066667rem;
}

.signCenterWrapper .signInfoItemDis {
  background-color: #F5F6F9;
  opacity: 0.4;
}

.signCenterWrapper .waitSignBottomBtn {
  position: fixed;
  left: 0;
  bottom: 1.933333rem;
  width: 100%;
  height: 1.653333rem;
  background-color: #FFFFFF;
  box-shadow: 0 -0.053333rem 0.373333rem 0 rgba(0, 0, 0, 0.07);
  display: flex;
  z-index: 10000000;
}

.signCenterWrapper .waitSignBottomBtn .allSelectWrap {
  flex: 2 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signCenterWrapper .waitSignBottomBtn .allSelectWrap .select {
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 0.106667rem;
  margin-right: 0.266667rem;
  position: relative;
}

.signCenterWrapper .waitSignBottomBtn .allSelectWrap .selectTrue {
  background-color: #29C1C2;
}

.signCenterWrapper .waitSignBottomBtn .allSelectWrap .selectNo {
  border: 0.04rem solid #DDDDDD;
}

.signCenterWrapper .waitSignBottomBtn .allSelectWrap .selectNo_checkSemi {
  border: 0.04rem solid #29C1C2;
  background-color: #29C1C2;
}

.signCenterWrapper .waitSignBottomBtn .allSelectWrap .check {
  border: 0.04rem solid #FFFFFF;
  border-left: 0;
  border-bottom: 0;
  height: 0.106667rem;
  position: absolute;
  left: 0.066667rem;
  bottom: 0.2rem;
  width: 0.266667rem;
  transform: rotate(135deg) scaleY(1);
}

.signCenterWrapper .waitSignBottomBtn .allSelectWrap .checkSemi {
  border: 0.066667rem solid #FFFFFF;
  border-left: 0;
  border-bottom: 0;
  height: 0;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);
  width: 0.2rem;
}

.signCenterWrapper .waitSignBottomBtn .selectBtnWrap {
  flex: 4 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signCenterWrapper .waitSignBottomBtn .selectBtnWrap .selectBtn {
  flex: 1 1;
  margin-left: 0.373333rem;
  margin-right: 0.373333rem;
  height: 1.12rem;
  line-height: 1.12rem;
}

.switchAccountBtn {
  width: 2.933333rem;
  min-width: 2.933333rem;
  height: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  border-radius: 0.373333rem;
  font-weight: 400;
  font-size: 0.373333rem;
}

.signConfirmWrapper :global .am-list-item {
  background-color: #f8f9fb;
  border-radius: 0.213333rem;
}

.signConfirmWrapper :global .am-list-item .am-input-control input,
.signConfirmWrapper :global .am-list-item .am-input-extra {
  font-size: 0.373333rem;
}

.signConfirmWrapper .hidedInput {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -10;
  width: 0;
  height: 0;
}

.signConfirmWrapper .topTip {
  font-size: 0.32rem;
  font-weight: 400;
  color: #FB783A;
  line-height: 0.533333rem;
  background-color: rgba(255, 131, 50, 0.1);
  padding: 0.266667rem 0.48rem;
}

.signConfirmWrapper .bodyPadding {
  padding: 0.266667rem 0.48rem;
}

.signConfirmWrapper .bodyPadding .title {
  color: #666666;
  font-size: 0.373333rem;
  font-weight: 400;
}

.signConfirmWrapper .bodyPadding .value {
  color: #333333;
  font-size: 0.48rem;
  font-weight: 500;
}

.signConfirmWrapper .color8C8C8C {
  color: #8C8C8C;
}

.faceModal {
  position: relative;
  margin: auto;
  top: 3.28rem;
  width: 8.24rem;
  height: 10.4rem;
}

.faceModal :global .am-modal-content {
  border-radius: 0.266667rem;
  border: 0.013333rem solid #2BB0B3;
  background: linear-gradient(132deg, #FFFFFF 0%, #EDFEFF 100%);
}

.faceModal :global .am-modal-content .am-modal-body {
  padding: 0.853333rem;
}

.faceModal .faceModalImg img {
  width: 100%;
}

.faceModal .faceModalBtnList :global .am-button-primary {
  box-shadow: 0.133333rem 0.133333rem 0.4rem rgba(9, 173, 173, 0.25);
}