body {
  background-color: #ffffff !important;
  font-size: 0.373333rem !important;
}

* {
  margin: 0;
  padding: 0;
}

a {
  color: #333333;
}

ul,
li {
  list-style: none;
}

.font-mini {
  /* 14px */
  font-size: 0.32rem !important;
}

.font-sml {
  /* 14px */
  font-size: 0.373333rem !important;
}

.font-mid {
  /* 16px */
  font-size: 0.426667rem !important;
}

.font-midl {
  /* 18px */
  font-size: 0.48rem !important;
}

.font-midxl {
  /* 20px */
  font-size: 0.533333rem !important;
}

.font-big {
  /* 24px */
  font-size: 0.64rem !important;
}

.font-bold-mini {
  font-weight: 400;
}

.font-bold-sml {
  font-weight: 500;
}

.font-bold-mid {
  font-weight: 600;
}

.font-bold-big {
  font-weight: 700;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-line-sml {
  line-height: 1.5;
}

/* 字体颜色 */

.primary {
  color: #29C1C2 !important;
}

.light-green {
  color: rgba(120, 169, 175, 1);
}

.light-gray {
  color: rgba(170, 170, 170, 1);
}

.warning {
  color: #FFB05C !important;
}

.danger {
  color: #f00 !important;
}

.gray {
  color: #989ba5 !important;
}

.gray1 {
  color: #ccc !important;
}

.gray-font {
  color: #adadad !important;
}

.black {
  color: #333333 !important;
}

.block-243B3C {
  color: #243B3C !important;
}

.color-8C8C8C {
  color: #8C8C8C;
}

.gray-icon {
  color: #989ba5 !important;
}

.white {
  color: #ffffff !important;
}

/* 背景颜色 */

.bg-gray {
  background: rgba(248, 249, 251, 1) !important;
}

.bg-primary {
  background-image: linear-gradient(#26B0B3, #3FB3B6);
}

.bg-banner {
  background: linear-gradient(180deg, #0F313C 0%, #24797C 100%);
}

.border-primary {
  border-bottom: 0.066667rem solid #3fc9cd;
}

.border-trans {
  border-bottom: 0.066667rem solid transparent;
}

/* 单行省略 */

.single-ellipsis {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* 2行省略号 兼容问题比较严重 */

.two-line-ellipsis {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.text-vertical {
  display: inline-block;
  vertical-align: middle;
}

.text-break {
  word-break: break-all;
}

.full-width {
  width: 100%;
}

.mt5 {
  margin-top: 0.133333rem;
}

.mt9 {
  margin-top: 0.24rem;
}

.mt10 {
  margin-top: 0.266667rem;
}

.mt15 {
  margin-top: 0.4rem;
}

.mt20 {
  margin-top: 0.533333rem;
}

.mt40 {
  margin-top: 1.066667rem;
}

.mb5 {
  margin-bottom: 0.133333rem;
}

.mb10 {
  margin-bottom: 0.266667rem;
}

.mb20 {
  margin-bottom: 0.533333rem;
}

.mb18 {
  margin-bottom: 0.48rem;
}

.ml5 {
  margin-left: 0.133333rem;
}

.ml10 {
  margin-left: 0.266667rem;
}

.mr10 {
  margin-right: 0.266667rem;
}

.mlr5 {
  margin: 0 0.133333rem;
}

.mlr10 {
  margin: 0 0.266667rem;
}

.mlr20 {
  margin: 0 0.533333rem;
}

.mtb5 {
  margin: 0.133333rem 0;
}

.mtb10 {
  margin: 0.266667rem 0;
}

.pall5 {
  padding: 0.133333rem;
}

.pall10 {
  padding: 0.266667rem;
}

.pall20 {
  padding: 0.533333rem;
}

.plr10 {
  padding: 0 0.266667rem;
}

.plr20 {
  padding: 0 0.533333rem;
}

.pbb80 {
  padding-bottom: 1.066667rem !important;
}

.plr14 {
  padding: 0 0.373333rem;
}

.plr20tb10 {
  padding: 0.266667rem;
}

.letter-spacing10 {
  letter-spacing: 0.133333rem;
}

.font-weight600 {
  font-weight: 600;
}

.ptb20 {
  padding: 0.533333rem 0;
}

.border-radius10 {
  border-radius: 0.266667rem;
}

.pb20 {
  padding-bottom: 0.533333rem;
}

/****************** flex ******************/

.box-flex {
  display: flex !important;
}

.inline-block {
  display: inline-block;
}

.item-flex {
  flex: 1 1;
}

.flex-1 {
  flex: 1 1;
}

.flex-2 {
  flex: 2 1;
}

.flex-3 {
  flex: 3 1;
}

.flex-4 {
  flex: 4 1;
}

.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.justify-start {
  justify-content: flex-start !important;
}

.justify-center {
  justify-content: center !important;
}

.justify-between {
  justify-content: space-between !important;
}

.justify-around {
  justify-content: space-around !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.align-self {
  align-self: center !important;
}

.text-center {
  text-align: center;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.align-start {
  align-self: flex-start !important;
}

.align-end {
  align-self: flex-end !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

/* bottom-btn */

.bottomButtonFixedContent {
  margin-bottom: 1.333333rem;
}

.bottomButtonFixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.bottomFixedContent {
  margin-bottom: 2.666667rem;
}

.bottomFixedWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  /* min-height: 180px; */
  background: #FFFFFF;
  box-shadow: 0 0.08rem 0.186667rem 0 rgba(9,173,173,0.3000);
  /* box-shadow: 0px -4px 32px 0px rgba(0, 0, 0, 0.07); */
  border-radius: 0.533333rem 0.533333rem 0 0;
}

/* top-banner */

.banner-Wrapper {
  background: linear-gradient(180deg, #07182A 0%, #195052 31%, #194E4F 100%);
  /* background: linear-gradient(180deg, #0F313C 0%, #24797C 100%); */
  color: #fff;
  padding: 0.693333rem 0.48rem 0.933333rem;
}

.banner-Wrapper-after {
  position: relative;
}

.banner-Wrapper-after::after {
  position: absolute;
  content: '';
  width: 100%;
  left: 0;
  right: 0;
  bottom: -0.026667rem;
  height: 0.8rem;
  background-color: #fff;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
}

/* tab */

.commonTabName {
  display: inline-block;
  padding-bottom: 0.266667rem;
}

/* 垂直居中 */

.centerWrapper {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.centerWrapperContent {
  width: 100%;
  text-align: center;
  padding: 0 0.533333rem;
}

/* loading */

.loader-box {
  position: relative;
  width: 100%;
  height: 100vh;
}

.loader-content {
  position: absolute;
  top: 30%;
  left: 50%;
  margin-left: -1.68rem;
  width: 3.36rem;
  height: 3.226667rem;
  border-radius: 0.346667rem;
  background-color: #243B3C;
}

.loader-contentImg {
  width: 2.666667rem;
  margin-left: 0.346667rem;
  margin-top: -0.346667rem;
}

.loader-tips {
  margin-top: -0.613333rem;
  line-height: 0.56rem;
  color: #fff;
  text-align: center;
  font-size: 0.373333rem;
}

.protoTiscom {
  position: absolute;
  top: -1.066667rem;
  left: 0.133333rem;
  z-index: 100;
  color: #fff;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 0.133333rem;
  padding: 0.2rem;
}

.protoTiscom:after {
  position: absolute;
  left: 0.333333rem;
  bottom: -0.4rem;
  content: '';
  width: 0;
  height: 0;
  border-width: 0.293333rem;
  border-style: solid dotted dotted;
  border-color: rgba(0, 0, 0, 1) transparent transparent;
}

.disabled {
  background-color: #F5F6F9;
}