.signResultWrapper {
  padding-bottom: 1.6rem;
}

.signResultWrapper .bottomButton {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.973333rem;
  background: #FFFFFF;
  box-shadow: 0 -0.053333rem 0.16rem 0 rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  font-size: 0.373333rem;
  font-weight: 400;
  color: #29C1C2;
  line-height: 1.973333rem;
  z-index: 300;
}

.signResultWrapper .fs40 {
  font-size: 0.533333rem;
  color: #FFB05C;
}

.signResultWrapper .bingCardWrap {
  margin: 0.8rem 0.48rem 0.266667rem;
  height: auto;
}

.signResultWrapper .bingCardWrap .title {
  color: #333333;
  font-size: 0.373333rem;
  font-weight: 400;
  line-height: 0.4rem;
  margin-bottom: 0.373333rem;
}

.signResultWrapper .bingCardWrap .btn {
  margin-top: 0.533333rem;
  height: 1.2rem;
  border-radius: 0.666667rem;
  border: 0.013333rem solid #26B0B3;
  background: rgba(46, 213, 210, 0.1);
  line-height: 1.2rem;
  text-align: center;
  font-size: 0.426667rem;
  font-weight: 500;
  color: #29c1c2;
}

.signResultWrapper .bingCardWrap .btn2 {
  margin-top: 0.266667rem;
  height: 0.666667rem;
  line-height: 0.666667rem;
  text-align: center;
  font-size: 0.426667rem;
  color: #29c1c2;
  font-weight: 500;
}

.signResultWrapper .bingCardWrap .task {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.signResultWrapper .bingCardWrap .task .wrap {
  width: 7.68rem;
  background-color: #ffffff;
  border-radius: 0.266667rem;
  overflow: hidden;
}

.signResultWrapper .bingCardWrap .task .wrap .text1 {
  margin: 0.533333rem 0.693333rem 0.066667rem;
  line-height: 0.6rem;
}

.signResultWrapper .bingCardWrap .task .wrap .btnWrap {
  height: 1.253333rem;
  border-top: 0.013333rem solid #e9ebeb;
  display: flex;
  color: #243b3c;
  font-size: 0.426667rem;
  font-weight: 400;
}

.signResultWrapper .bingCardWrap .task .wrap .btnWrap .borderleft {
  border-left: 0.013333rem solid #e9ebeb;
}