.progressWraper {
  width: 5.866667rem;
  margin: 7.466667rem auto 0.4rem;
  height: 0.106667rem;
  border-radius: 0.066667rem;
  position: relative;
  background-color: #F5F6F9;
}

.progressWraper .innerWraper {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: inherit;
  background: #29C1C2;
  border-radius: 0.066667rem;
}

.progressWraper .innerWraper::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 0.56rem;
  height: 0.56rem;
  background: url(../../assets/icons/progress.png);
  background-size: 100% 100%;
  transform: translate(50%, -40%);
}

.innerText {
  width: 5.866667rem;
  margin: 0.133333rem auto;
  height: 0.533333rem;
  line-height: 0.533333rem;
  font-size: 0.426667rem;
  text-align: center;
  color: #29C1C2;
  font-weight: 500;
}