.personalCenterWrapper .switchAccountBtn {
  width: 2.933333rem;
  min-width: 2.933333rem;
  height: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  border-radius: 0.373333rem;
  font-weight: 400;
  font-size: 0.373333rem;
}

:global .personalQuitResetWrapper .am-modal-content {
  border-radius: 0.8rem 0.8rem 0 0;
}

:global .personalQuitResetWrapper .quitModelItem {
  position: relative;
  height: 1.6rem;
  line-height: 1.6rem;
}

:global .personalQuitResetWrapper .quitModelItem:not(:last-child) {
  padding-bottom: 0.266667rem;
}

:global .personalQuitResetWrapper .quitModelItem:not(:last-child)::after {
  position: absolute;
  content: '';
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0.266667rem;
  background-color: #f3f3f3;
}