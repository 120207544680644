/* 复选框 */

.am-checkbox,
.am-checkbox-inner {
  width: 0.426667rem;
  height: 0.426667rem;
}

.am-checkbox-inner:after {
  top: 0;
  right: 0.133333rem;
  width: 0.106667rem;
  height: 0.24rem;
}

/* button */

.am-button-inline {
  /* padding: 0 60px; */
}

.am-button {
  font-size: 0.426667rem;
  border-radius: 0.8rem;
}

.am-button-ghost::before {
  border-radius: 1.333333rem  !important;
}