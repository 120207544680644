.wraper .verifyForm {
  margin-top: 0.32rem;
  display: flex;
  height: 1.493333rem;
  align-items: center;
  justify-content: space-between;
  align-self: center;
}

.wraper .verifyForm span {
  display: flex;
  flex: 1 1;
  height: 1.493333rem;
  border-radius: 0.213333rem;
  background-color: #F5F6F9;
  color: #1c2525;
  font-size: 0.48rem;
  font-weight: 600;
  line-height: 1.493333rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-left: 0.266667rem;
}

.wraper .verifyForm .highLight {
  box-sizing: border-box;
  border: 0.013333rem solid #29C1C2;
}

.wraper .verifyForm .noMarginRight {
  margin-right: 0;
}

.wraper .hidedInput {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -10;
  width: 0;
  height: 0;
}