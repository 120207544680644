.homeWrapper {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
}

:global .homeResetWrapper .am-tab-bar-bar {
  height: 1.866667rem;
  box-shadow: 0 -0.106667rem 0.133333rem 0 rgba(0, 0, 0, 0.05);
}

:global .homeResetWrapper .am-tab-bar-bar::before {
  display: none !important;
}

:global .homeResetWrapper .am-tab-bar-bar .am-tab-bar-tab-title {
  margin-top: 0.133333rem;
}

:global .homeResetWrapper .am-list-item .am-list-line .am-list-content {
  color: #333;
  font-size: 0.426667rem;
}

:global .homeResetWrapper .am-list-item {
  border-bottom: 0.013333rem solid #F4F4F4;
  padding-left: 0;
  margin-left: 0.48rem;
  margin-bottom: 0.4rem;
}

:global .homeResetWrapper .am-list-body::after,
:global .homeResetWrapper .am-list-body::before,
:global .homeResetWrapper .am-list-line::after {
  display: none !important;
}

:global .homeResetWrapper .am-tab-bar-bar,
:global .homeResetWrapper .am-list-body,
:global .homeResetWrapper .am-list-body div:not(:last-child) .am-list-line {
  border: 0;
}