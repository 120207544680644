.activityWrap {
  padding: 1.333333rem 0.293333rem 0.266667rem;
  height: auto;
}

.activityWrap .tip {
  display: block;
  height: 0.013333rem;
  width: 100%;
  border-top: 0.013333rem solid #dadada;
  position: relative;
}

.activityWrap .tip .text {
  position: absolute;
  background-color: #fff;
  padding: 0 0.186667rem;
  font-weight: 500;
  color: #333333;
  font-size: 0.32rem;
  left: 50%;
  transform: translate(-50%) translateY(-50%);
}

.activityWrap .intro {
  color: #999999;
  font-size: 0.373333rem;
  line-height: 0.533333rem;
  font-weight: 400;
}

.activityWrap .list {
  display: block;
  border-radius: 0.266667rem;
  background-color: #f5f6f9;
  border-collapse: collapse;
  margin-bottom: 0.32rem;
}

.activityWrap .list .listwrap {
  display: flex;
  justify-content: center;
  border-collapse: collapse;
}

.activityWrap .list .listli {
  border-top: 0.013333rem solid #e9ebeb;
  flex: 1 1;
  border-collapse: collapse;
  height: 1.066667rem;
  font-size: 0.32rem;
  text-align: center;
  color: #506263;
  box-sizing: border-box;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.activityWrap .list .borderno {
  border: none;
}

.activityWrap .activityIcon {
  margin-top: 0.48rem;
  margin-bottom: 0.32rem;
  width: 100%;
  padding-top: 0.213333rem;
  padding-bottom: 0.186667rem;
  box-sizing: border-box;
  position: relative;
  display: flex;
  font-size: 0.426667rem;
  font-weight: 600;
  color: #274F50;
  line-height: 0.426667rem;
}

.activityWrap .activityIcon .activityIconimg {
  position: absolute;
  left: 0;
  top: 0;
  width: 6.133333rem;
}

.activityWrap .activityIcon .dot {
  margin-left: 0.48rem;
  padding-right: 0.24rem;
  border-right: 0.013333rem dashed #333333;
  z-index: 100;
}

.activityWrap .activityIcon .title {
  margin-left: 0.266667rem;
  z-index: 100;
}

.activityWrap .phone {
  display: flex;
  width: 100%;
  margin: 0.533333rem 0;
  align-self: center;
  justify-content: center;
  align-items: center;
}