.selectAccountWrapper {
  background-color: #f5f6f9;
  padding-bottom: 2.533333rem;
  min-height: 100vh;
  box-sizing: border-box;
}

.selectAccountWrapper .accountContent {
  padding: 0.426667rem 0.8rem 0.426667rem 0.533333rem;
  margin-bottom: 0.373333rem;
  border-radius: 0.853333rem;
  font-weight: 400;
  color: #243b3c;
  background: #ffffff;
  white-space: nowrap;
  box-sizing: border-box;
  display: flex;
  position: relative;
}

.selectAccountWrapper .accountContent .label {
  position: absolute;
  right: 0.533333rem;
  top: 50%;
}

.selectAccountWrapper .accountContent .label::after {
  box-sizing: content-box;
  content: "";
  border: 0.066667rem solid #2cb0b3;
  border-left: 0;
  border-bottom: 0;
  height: 0.106667rem;
  position: absolute;
  left: -0.2rem;
  bottom: 0;
  width: 0.333333rem;
  transform: rotate(135deg) scaleY(1);
}

.selectAccountWrapper .accountContent .displayNone {
  display: none;
}

.selectAccountWrapper .accountActive {
  background-color: rgba(46, 213, 210, 0.1);
  border: 0.026667rem solid #3fc9cd;
}

.selectAccountWrapper .accountName {
  font-size: 0.426667rem;
  font-weight: 600;
}

.selectAccountWrapper .chooseText {
  display: inline-block;
  vertical-align: middle;
}

.selectAccountWrapper .chooseAccount {
  width: auto;
  max-width: 3.333333rem;
  padding: 0 0.066667rem;
}