.bottomFixedWrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 0.373333rem 0.053333rem;
  background: #FFFFFF;
  box-shadow: 0 -0.053333rem 0.426667rem 0 rgba(0, 0, 0, 0.12);
}

.bottomFixedWrapper .btn {
  background-color: #3DB3B6;
  color: #FFFFFF;
  font-weight: 600;
  box-shadow: 0 -0.053333rem 0.426667rem 0 rgba(0, 0, 0, 0.12);
}

.bottomFixedWrapper .wbtn {
  background-color: #FFFFFF;
  border: 0.026667rem solid #DEE3E3;
  font-weight: 600;
  box-shadow: 0 -0.053333rem 0.426667rem 0 rgba(0, 0, 0, 0.12);
}