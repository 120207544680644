.CardWrap {
  margin: 0.533333rem 0.48rem;
  height: auto;
  border-radius: 0.4rem;
  background-color: #2CB0B3;
  padding: 0.48rem 0.533333rem 0.453333rem;
}

.CardWrap .bankName {
  font-size: 0.373333rem;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 0.4rem;
  margin-bottom: 0.213333rem;
}

.CardWrap .bankNameTip {
  font-size: 0.293333rem;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 0.32rem;
  margin-bottom: 0.453333rem;
}

.CardWrap .bankNo {
  font-size: 0.48rem;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 0.56rem;
  letter-spacing: 0.133333rem;
}