.bankCardWrap {
  height: auto;
  padding-top: 0.533333rem;
  padding: 0.533333rem;
}

.bankCardWrap .bankCardLi {
  padding: 0.48rem 0.533333rem;
  background-color: rgba(44, 176, 179, 0.2);
  border-radius: 0.4rem;
  margin-bottom: 0.533333rem;
  font-size: 0.293333rem;
  color: #ffffff;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.bankCardWrap .bankCardLiUn {
  padding: 0.48rem 0.533333rem;
  border-radius: 0.4rem;
  margin-bottom: 0.533333rem;
  font-size: 0.293333rem;
  color: #ffffff;
  position: relative;
  overflow: hidden;
  background-color: #2cb0b3;
  box-sizing: border-box;
}

.bankCardWrap .isChoose {
  background-color: #2cb0b3;
  border: 0.013333rem solid #2cb0b3;
}

.bankCardWrap .noData {
  text-align: center;
  margin-top: 1.333333rem;
  color: #8c8c8c;
  font-size: 0.373333rem;
}

.bankCardWrap .triangle {
  position: absolute;
  right: 0;
  top: 0;
  border-left: 0.666667rem solid transparent;
  border-right: 0.666667rem solid #ffffff;
  border-bottom: 0.666667rem solid transparent;
  border-top: 0.666667rem solid #ffffff;
  width: 0;
  height: 0;
  z-index: 100;
}

.bankCardWrap .triangle::after {
  box-sizing: content-box;
  content: "";
  border: 0.066667rem solid #2cb0b3;
  border-left: 0;
  border-bottom: 0;
  height: 0.133333rem;
  left: 0.04rem;
  position: absolute;
  bottom: 0.2rem;
  width: 0.4rem;
  transform: rotate(135deg) scaleY(1);
}

.bankCardWrap .bottomBtn {
  position: fixed;
  left: 0.48rem;
  right: 0.48rem;
  bottom: 0.266667rem;
  display: block;
  padding-top: 0.266667rem;
}

.paddingBottom {
  padding-bottom: 1.333333rem;
}

.tabBar {
  height: 1.12rem;
  color: #879394;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  font-size: 0.373333rem;
  font-weight: 400;
}