.loginWrapper {
  height: 100vh;
  display: flex;
  padding: 0 0.533333rem;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.loginWrapper .loginBox {
  flex: 1 1;
  font-size: 0.32rem;
}

.loginWrapper .logo img {
  display: block;
  margin: 0 auto 1.333333rem;
  width: 90%;
}

.loginWrapper .logoBottom img {
  display: block;
  margin: 2.133333rem auto 0;
  width: 3.466667rem;
}

.loginWrapper :global .login-item-wrapper .am-list-body:before,
.loginWrapper :global .login-item-wrapper .am-list-body:after {
  background-color: transparent;
}

.loginWrapper :global .login-item-wrapper .am-list-body .am-list-item {
  background-color: #f8f9fb;
  border-radius: 0.8rem;
}

.loginWrapper :global .login-item-wrapper .code .am-list-line {
  padding-right: 0;
}

.loginWrapper :global .login-item-wrapper .code .am-list-line .am-input-extra {
  position: absolute;
  max-height: 100%;
  right: 0;
  top: 0;
  bottom: 0;
}

.loginWrapper :global .login-item-wrapper .code .am-list-line .am-input-extra img {
  height: 100%;
  width: 100%;
}

.loginWrapper :global .login-item-wrapper .am-list-item .am-input-control input {
  font-size: 0.426667rem;
}

.loginWrapper .myList img {
  width: 1.333333rem;
  height: auto;
}

.loginWrapper .protoTisContent {
  position: relative;
}

.loginWrapper .protoTisContent .protoTis {
  position: absolute;
  top: -1.066667rem;
  left: 0;
  z-index: 100;
  color: #fff;
  background-color: #000000;
  border-radius: 0.133333rem;
  padding: 0.266667rem;
}

.loginWrapper .protoTisContent .protoTis:after {
  position: absolute;
  left: 0.186667rem;
  bottom: -0.533333rem;
  content: '';
  width: 0;
  height: 0;
  border-width: 0.293333rem;
  border-style: solid dotted dotted;
  border-color: #000000 transparent transparent;
}