.wrapper {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.wrapper .notfound {
  width: 100%;
  text-align: center;
  padding: 0 0.533333rem;
}