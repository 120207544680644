.bindCardWraper {
  padding-bottom: 2.133333rem;
  height: auto;
  position: relative;
}

.bindCardWraper .hidedInput {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -10;
  width: 0;
  height: 0;
}

.bindCardWraper .errorTipView {
  margin: 0.266667rem 0.48rem;
  height: auto;
}

.bindCardWraper .errorTipView .title {
  color: #FF2828;
  font-size: 0.48rem;
  font-weight: 600;
  line-height: 0.533333rem;
}

.bindCardWraper .errorTipView .titleTip {
  font-size: 0.32rem;
  line-height: 0.4rem;
  color: #FF2828;
  font-weight: 5.333333rem;
  margin-top: 0.133333rem;
}

.bindCardWraper .phoneWrap {
  margin: 0.533333rem 0.48rem;
  height: auto;
}

.bindCardWraper .phoneWrap .title {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.4rem;
  margin-bottom: 0.266667rem;
}

.bindCardWraper .phoneWrap .phoneValue {
  font-size: 0.48rem;
  font-weight: 500;
  color: #333333;
  line-height: 0.533333rem;
}

.bindCardWraper .shortMessWrap {
  margin: 0.533333rem 0.48rem;
}

.bindCardWraper .shortMessWrap .title {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.4rem;
}

.bindCardWraper .smallPayWrap {
  margin: 0.533333rem 0.48rem;
}

.bindCardWraper .smallPayWrap .title {
  font-size: 0.373333rem;
  font-weight: 400;
  color: #666666;
  line-height: 0.4rem;
  margin-bottom: 0.213333rem;
}

.bindCardWraper .smallPayWrap .titleTip {
  font-size: 0.32rem;
  font-weight: 400;
  color: #CCCCCC;
  line-height: 0.346667rem;
  margin-bottom: 0.32rem;
}

.bindCardWraper .smallPayWrap .titleTip .span {
  color: #333333;
}

.bindCardWraper .smallPayWrap .line {
  margin: 0.506667rem 0;
  height: 0.013333rem;
  border-bottom: 0.013333rem solid #DDDDDD;
}

.bindCardWraper .smallPayWrap .bottomBtnWrap {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto;
  padding-bottom: 0.4rem;
  background-color: #fff;
}

.bindCardWraper .smallPayWrap .bottomBtnWrap .btn {
  margin: 0 0.48rem;
}

.bindCardWraper .bindCardtips {
  margin-top: 0.133333rem;
}

.bindCardWraper :global .bindCard .am-list-line::after {
  display: none !important;
}

.bindCardWraper :global .bindCard .am-list-item .am-input-control .fake-input-container .fake-input-placeholder {
  text-align: left;
  font-size: 0.373333rem;
}

.bindCardWraper .getKnowBtn {
  position: fixed;
  bottom: 0.533333rem;
  left: 50%;
  width: 9.04rem;
  height: 1.333333rem;
  background: linear-gradient(180deg, #3DB3B6 0%, #25B0B3 100%);
  box-shadow: 0 0.106667rem 0.373333rem 0 rgba(9, 173, 173, 0.25);
  border-radius: 0.666667rem;
  text-align: center;
  line-height: 1.333333rem;
  transform: translate(-50%, 0);
  color: #FFFFFF;
  font-weight: 500;
}